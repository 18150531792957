<template>
  <div>
    <div class="main">
      <div
        class="bVenueBox"
        :style="{ background: bvenueData.background_color }"
      >
        <div class="shopingCart" @click="routerLink">
          <div v-if="bvenueData && bvenueData.cartTotal !== 0" class="CartBox">
            <span>{{ bvenueData.cartTotal }}</span>
          </div>
        </div>
        <div class="bVenueBanner">
          <img
            v-if="bvenueData.background_images"
            :src="bvenueData.background_images"
          >
          <img v-else src="@/assets/images/bVenueBanner.png">
        </div>
        <div style="padding: 0 7px; margin-bottom: 14px">
          <div class="bVenueTimeBox">
            <CountDown :timer="bvenueData.countdown" />
            <!-- <CouponList
              v-if="bvenueData.coupon !== 0"
              :data="bvenueData.coupon"
              @gotoele="Tocontone"
            /> -->
          </div>
          <!-- <div class="lowestBox" @click="gotoDetail(bvenueData.singleItem)">
            <img :src="bvenueData.singleItem.image" />

          </div> -->
        </div>

        <div class="shopListBox">
          <ul :class="$route.query.pageType ? 'two-shoplist' : 'col-shoplist'">
            <li
              v-for="(item, index) in bvenueData.goodList"
              :key="index"
              @click="godetail(item)"
            >
              <div class="col-shopImg">
                <imgLoading :src="item.goodCover" />
              </div>
              <div class="col-shopInfoBox">
                <!-- @click.stop="showSku(item)" -->

                <h4 class="col-shopTitle">
                  <!-- <span>满减</span> -->
                  {{ item.goodName }}
                </h4>
                <!-- <div class="col-tag">买400ml送400ml</div> -->
                <div v-if="$route.query.pageType" class="col-PriceBox">

                  <div class="goodPrice">
                    <span>原价¥{{ item.goodPrice }}</span>
                  </div>

                  <div class="goodVipPrice">
                    活动价¥<em>{{ item.goodVipPrice }}</em>
                  </div>
                </div>

                <div v-else class="col-PriceBox">
                  ¥<em>{{ item.goodVipPrice }}</em>
                  <span>¥{{ item.goodPrice }}</span>
                </div>

                <div class="col-btn">
                  <img
                    v-if="$route.query.pageType"
                    src="@/assets/images/two-btn.png"
                  >
                  <img v-else src="@/assets/images/col-btn.png">

                  <div @click.stop="showSku(item)">加入购物车</div>

                </div>
              </div>

            </li>
          </ul>
        </div>

        <!-- <van-tabs
          v-model="active"
          scrollspy
          sticky
          line-height="2px"
          line-width="100px"
          title-active-color="#000"
          :background="bvenueData.background_color"
        >
          <van-tab
            v-for="(itme, index) in brandList"
            :key="index"
            :title="itme.name"
          >

            <div ref="contone">
              <div class="bvenueTitle">
                {{ itme.name }}

              </div>
              <ul class="bvenueListBox">
                <li v-for="(goods, i) in itme.goodList" :key="i">
                  <div class="bvenueListImg" @click="godetail(goods)">
                    <img class="img" :src="goods.goodCover" />
                  </div>
                  <div style="padding: 0 7px">
                    <h4 @click="godetail(goods)">
                      {{ goods.goodName }}
                    </h4>
                    <h5 @click="godetail(goods)">
                      7天已售{{ goods.soldTotal }}件
                    </h5>

                    <Progress
                      :addGray="false"
                      :progressWidth="
                        computeWidth(goods.soldTotal, goods.UnsoldTotak)
                      "
                    />

                    <div class="bvenuePriceBox">
                      ¥{{ goods.goodVipPrice
                      }}<span>¥{{ goods.goodPrice }}</span>
                    </div>
                    <div
                      v-if="goods.product_type === 1"
                      class="AddToCart"
                      @click="showSku(goods)"
                    >
                      <img src="@/assets/images/AddToCart.png" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </van-tab>
        </van-tabs> -->
        <MiniCart v-if="goodinfo" :goods-status="1" :datainfo="goodinfo" />
        <h4 class="copyright" />
      </div>
    </div>
  </div>
</template>
<script>
import imgLoading from '@/component/imgLoading'
import CountDown from '../component/Countdown'
// import CouponList from '../component/CouponList'
// import Progress from '../component/Progress'
import MiniCart from '@/component/GoodsDetail/MiniCart'
import { GetBrandVenueList } from '@/services/activity.js'
import { urlConversion, getClientInfo } from '@/utils/public.js'
import Vue from 'vue'
import { Tab, Tabs } from 'vant'
import { wxConfigInit } from '@/utils/wx-api'

Vue.use(Tab).use(Tabs)

export default {
  name: 'Bvenue',
  components: {
    CountDown,
    MiniCart,
    imgLoading
  },
  data() {
    return {
      bvenueData: null,
      showcart: false,
      goodsStatus: 2, // 1:自营详情；2:秒杀详情；3:京东详情；4:拼多多详情；5:淘宝详情；6:唯品会详情
      goodsInfo: {}, // 商品信息
      brandList: [],
      active: 0,
      show: false,
      couponList: [],
      goodinfo: null,
      uid: null
    }
  },
  created() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')
    })
  },
  mounted() {
    this.getBvenueList()
  },
  methods: {
    Tocontone() {
      this.$refs.contone[0].scrollIntoView({
        behavior: 'smooth'
      })
      // document.querySelector("#contone").scrollIntoView(true);
    },
    gotoDetail(row) {
      const obj = {
        product_type: row.productType,
        goodId: row.id,
        skuId: row.skuId
      }
      this.godetail(obj)
    },

    godetail(item) {
      this.$store.dispatch('godetail', item)

      // /goodsDetailZY?liveId='直播ID'&liveUserId='直播人ID'&parentTypeId='产品类型'&goodsId='产品ID'&skuid='产品规格ID'
      // this.$router.push({ path: "/goodsDetailMS", query: { goodsId: item.goodId, skuid: item.skuId,activityId:75 } })
    },
    // 计算横条的%
    computeWidth(etart, end) {
      // 跳转购买条 在运算基础上增加20
      const Num = (etart / (etart + end)) * 100 + 20

      return Num > 100 ? 100 : Num
    },
    // 获取分会场信息
    getBvenueList() {
      const venueId = this.$route.query.venueId

      const body = {
        // activity_id: 55,
      }

      let uid = ''
      if (getClientInfo()) {
        uid = this.$route.query.mid
      } else {
        uid = localStorage.getItem('uid')
        if (uid) {
          body.member_id = uid
        } else {
          this.$store.dispatch('loginIntercept', this.$route.query)
        }
      }

      if (venueId) {
        body.branch_venue_id = venueId
      } else {
        this.$router.push('/yEleven')
      }
      GetBrandVenueList(body).then((res) => {
        if (Number(res.code) === 200) {
          this.bvenueData = res.data
          // this.brandList = res.data.category;
          if (!getClientInfo()) {
            // const shareDataInfo = this.$store.state.shareData
            const obj = urlConversion('obj', window.location.href)
            const arr = []
            let _url = ''
            for (const key in obj.value) {
              if (key !== 'env') {
                arr.push(key + '=' + obj.value[key])
              }
            }
            _url = obj.Http + '?' + arr.join('&')

            const shareInfo = {
              title: res.data.shareData.title, // 分享标题
              desc: res.data.shareData.des, // 分享描述
              link: _url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: res.data.shareData.image // 分享图标
            }
            wxConfigInit(shareInfo)
            // this.$store.dispatch('wxConfigInit', shareInfo)
          }
        } else if (Number(res.code) === 400) {
          this.$router.push('/yEleven')
        }

        // if (Number(res.code) === 200) {
        //   let data = res.data;
        //   this.vebueData = data;
        // }
      })
    },
    routerLink() {
      // 路由跳转

      if (getClientInfo()) {
        const _Router = {
          path: 'router://shoppingCart' // 购物车
        }
        // 这里是跳转RN
        this.$store.dispatch('RNrouter', _Router)
      } else {
        this.$router.push({ path: '/shopCar' })
      }
    },
    showSku(goodinfo) {
      this.goodinfo = goodinfo
      this.$store.commit('changeShowSku', true)

      // if (window.localStorage.getItem("uid")) {
      //   this.$store.commit("changeShowSku", true);
      // } else {
      //   this.$store.dispatch("loginIntercept", this.$route.query);
      // }
    }
  }
}
</script>
<style lang="less" scoped>
#app {
  height: 100%;
}

html,
body {
  height: 100%;
}

.bVenueBox {
  .shopingCart {
    width: 43px;
    height: 43px;
    position: fixed;
    right: 7px;
    bottom: 30%;
    z-index: 5;
    background: url("../../../assets/images/shoppingCart.png") no-repeat;
    background-size: 100%;

    .CartBox {
      width: 100%;
      height: 100%;
      position: relative;

      span {
        position: absolute;
        top: -3px;
        right: -1px;
        z-index: 6;
        background: red;
        width: 18px;
        height: 18px;
        line-height: 20px;
        text-align: center;
        border-radius: 18px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
      }
    }
  }

  height: 100%;
  // background: #eb003f;
  width: 375px;

  .bVenueBanner {
    width: 375px;
    // height:421px;
  }

  .bVenueTimeBox {
    // height: 183.5px;
  }

  .lowestBox {
    width: 100%;
    height: 161px;

    img {
      width: auto;
      height: 100%;
      overflow: hidden;
    }
  }

  .copyright {
    color: #fff;
    padding: 30px 0 0 0;
  }

  .bvenueTitle {
    width: 100%;
    height: 60px;
    text-align: center;
    background: url("../../../assets/images/bvenueTitle.png") no-repeat;
    background-size: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 45px;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    letter-spacing: 2px;
  }

  .bvenueListBox {
    width: 100%;
    padding: 0 7px;
    // height: 75px;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;

    li {
      margin-right: 5px;

      &:nth-child(3n + 0) {
        margin-right: 0px;
      }

      width: 116px;
      height: 167px;
      margin-bottom: 7px;
      border-radius: 5px;
      background: #ffffff;
      line-height: 18px;
      position: relative;

      .bvenueListImg {
        width: 100%;
        height: 86px;

        img {
          height: 100%;
          width: auto;
        }
      }

      h4 {
        font-size: 10px;
        font-family: PingFang SC Regular, PingFang SC Regular-Regular;
        font-weight: 500;
        text-align: left;
        color: #010101;
        padding: 5.5px 0 5.5px 0;
        height: 32px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        line-height: 14px;
      }

      h5 {
        text-align: left;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        font-size: 7px;
        color: #999999;
      }

      .bvenuePriceBox {
        text-align: left;
        padding: 3px 0;
        font-size: 15px;
        color: #e33f44;

        span {
          margin-left: 5px;
          font-size: 11px;
          font-family: PingFang SC Regular, PingFang SC Regular-Regular;
          font-weight: 400;
          text-decoration: line-through;
          text-align: left;
          color: #999999;
        }
      }

      .AddToCart {
        width: 15.5px;
        height: 15.5px;
        position: absolute;
        right: 5px;
        bottom: 5px;
      }
    }
  }
}

.myWrap {
  transition: all ease 0.2s;
}

.myWrap-enter-active {
  animation: identifier 0.2s;
  overflow: hidden;
}

.myWrap-leave-active {
  animation: against 0.2s;
  overflow: hidden;
}

@keyframes identifier {
  from {
    height: 0;
  }

  to {
    height: 54px;
  }
}

@keyframes against {
  from {
    height: 54px;
  }

  to {
    height: 0;
  }
}

/deep/ .van-tabs__wrap {
  height: 36px;

  .van-tabs__nav {
    // background: transparent !important;

    .van-tab {
      color: #fff;
    }

    .van-tab--active {
      color: #fff !important;
      font-weight: bold;
      background: transparent;
    }
  }
}

/deep/ .van-tabs__line {
  background: #fff;
}

/deep/ .van-tabs__wrap {
  &::after {
    // border: 0 solid #ebedf0;
    border: none;
  }
}

.tabsListBox {
  background: #000;
}

// /deep/.van-tabs__wrap {
//   height: 36px;
//   .van-tabs__nav {
//     background: #cd1c34 !important;

//     .van-tab {
//       color: #fff;
//     }
//     .van-tab--active {
//       color: #fff !important;
//       background: #ff1c57;
//     }
//   }
// }

.shopListBox {
  padding: 12px;

  .two-shoplist {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;

    li {
      height: 305px;
      width: 171px;
      padding: 12px;
      box-sizing: border-box;
      margin-right: 9px;
      margin-bottom: 12px;
      border-radius: 10px;

      &:nth-child(2n + 0) {
        margin-right: 0px;
      }

      background: #fff;

      .col-shopImg {
        width: 100%;
        height: 151px;
        text-align: center;
        overflow: hidden;
        // background: #ccc;
        margin-right: 10px;
        border-radius: 5px;

        img {
          width: auto;
          height: 100%;
        }
      }

      .col-PriceBox {
        text-align: left;

        .goodPrice {
          padding: 0 0 3px 0;
          font-size: 15px;
          color: #999999;
          font-family: PingFang SC Regular, PingFang SC Regular-Regular;
          font-weight: 400;
          text-decoration: line-through;
        }

        .goodVipPrice {
          // height: 13px;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #E33F44;
          margin-bottom: 5px;

          em {
            font-size: 22px;
            font-style: normal;
          }

        }

      }

      .col-shopInfoBox {
        position: relative;
        width: 100%;
        height: 37px;
        text-align: center;

        .col-btn {
          // position: absolute;
          // right: 0;
          // left: 0;
          // bottom: 0;
          width: 119px;
          height: 37px;
          margin: 0 auto;
          // background: #000;
        }

        .col-shopTitle {
          text-align: left;
          margin: 0 0 10px 0;
          font-size: 14px;
          color: #333;
          line-height: 18px;
          width: 100%;
          overflow: hidden;
          font-weight: 500;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          height: 34px;
        }
      }
    }
  }

  .col-shoplist {
    li {
      width: 100%;
      height: 128px;
      text-align: left;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;

      .col-shopImg {
        width: 108px;
        height: 108px;
        text-align: center;
        background: #ccc;
        float: left;
        margin-right: 10px;
        overflow: hidden;

        img {
          width: auto;
          height: 100%;
        }
      }

      .col-shopInfoBox {
        float: left;
        width: 204px;
        height: 108px;
        position: relative;

        .col-shopTitle {
          font-size: 15px;
          height: 45px;
          color: #333;
          line-height: 22px;
          overflow: hidden;
          font-weight: 500;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;

          span {
            font-size: 13px;
            color: #fff;
            background: #e43f44;
            padding: 2px 5px;
            vertical-align: middle;
            margin-right: 3px;
            border-radius: 2px;
          }
        }

        .col-tag {
          display: inline-block;
          font-size: 13px;
          font-family: PingFang SC Medium, PingFang SC Medium-Medium;
          font-weight: 500;
          padding: 4px 12px;
          background: #ffe2e3;
          border-radius: 15px;
          // text-align: center;
          color: #e43f44;
          margin: 5px 0;
        }

        .col-PriceBox {
          font-size: 15px;
          color: #e33f44;
          font-weight: 500;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;

          em {
            font-size: 24px;
            font-style: normal;
          }

          span {
            font-size: 15px;
            color: #999999;
            font-family: PingFang SC Regular, PingFang SC Regular-Regular;
            font-weight: 400;
            text-decoration: line-through;
          }
        }

        .col-btn {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 47px;
          height: 47px;
          // background: #000;
        }
      }
    }
  }
}
</style>
